<template>
    <b-card title="Quick Link">
        <div class="menu-group">
            <div class="menu" v-if="$can('view', 'events')">
                <a :href="'/events/'+eventId+'/view'">Detail Event</a>
            </div>
            <div class="menu" v-if="$can('view', 'fields')">
                <a :href="'/events/'+eventId+'/fields'">Custom Field</a>
            </div>
            <div class="menu" v-if="$can('view', 'fields')">
                <a :href="'/events/'+eventId+'/form-setting'">Form Settings</a>
            </div>
            <div class="menu" v-if="$can('view', 'event_participants')">
                <a :href="'/events/'+eventId+'/participants'">List Participant</a>
            </div>
            <div class="menu" v-if="$can('view', 'event_booths')">
                <a :href="'/events/'+eventId+'/booths'">Booths</a>
            </div>
            <div class="menu" v-if="$can('view', 'event_scans')">
                <a :href="'/events/'+eventId+'/scans'">Absensi</a>
            </div>
            <div class="menu" v-if="$can('view', 'event_stats')">
                <a :href="'/events/'+eventId+'/stats'">Reporting</a>
            </div>
            <div class="menu" v-if="$can('view', 'event_managers')">
                <a :href="'/events/'+eventId+'/managers'">Client Access</a>
            </div>
            <div class="menu" v-if="$can('view', 'questions')">
                <a :href="'/events/'+eventId+'/questions'">Feedback Form</a>
            </div>
            <div class="menu" v-if="$can('view', 'event_emails')">
                <a :href="'/events/'+eventId+'/email'">Email</a>
            </div>
        </div>
    </b-card>
</template>

<script>
import {
  BCard
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
    },
    props: {
        eventId: {
            type: String,
            required: true,
        },
    },
    // data() {

    // },
    computed: {

    },
    methods: {
        
    },
    created() {
        // this.fetchData()
    },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.responses-table .vgt-responsive {
  min-height: 350px;
}

.menu-group{
    display: flex;
}

.menu-group .menu{
    padding-right: 40px;
}
</style>